import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import SocialBar from "../components/SocialBar"
import SectionText from "../components/SectionText"

const Contact = () => {
  //querying for contact info (email, phone,adress) and opening hours data
  const contactData = useStaticQuery(graphql`
    query contactQuery {
      allDatoCmsContactinfo {
        edges {
          node {
            email
            telefon
            adres
          }
        }
      }
      allDatoCmsWorkingHour {
        edges {
          node {
            dayOfWeek
            closingHour
            openingHour
          }
        }
      }
    }
  `)
  const contactDatoData = contactData.allDatoCmsContactinfo.edges[0].node
  const contactHoursData = contactData.allDatoCmsWorkingHour.edges

  //mapping opening hours data, it returns single day styled component
  const contactHoursArray = contactHoursData.map(day => {
    const openingDate = new Date(day.node.openingHour)
    const closingDate = new Date(day.node.closingHour)
    const openingHour =
      openingDate.getHours() < 10
        ? "0" + openingDate.getHours()
        : openingDate.getHours()
    const openingMinute =
      openingDate.getMinutes() < 10
        ? "0" + openingDate.getMinutes()
        : openingDate.getMinutes()
    const closingHour =
      closingDate.getHours() < 10
        ? "0" + closingDate.getHours()
        : closingDate.getHours()
    const closingMinute =
      closingDate.getMinutes() < 10
        ? "0" + closingDate.getMinutes()
        : closingDate.getMinutes()

    return (
      <ContactHourInfo key={day.node.dayOfWeek}>
        <span className="weekday">{day.node.dayOfWeek}:</span>
        <span className="hours">
          {openingHour}:{openingMinute}-{closingHour}:{closingMinute}
        </span>
      </ContactHourInfo>
    )
  })

  return (
    <>
      <Layout>
        <Seo title="Kontakt" />
        <ContactContainer>
          <ContactInfo>
            <ContactHours>
              <h2>Godziny otwarcia:</h2>
              {contactHoursArray}
            </ContactHours>
            <ContactText>
              <SectionText>{contactDatoData.adres}</SectionText>
              <SectionText>{contactDatoData.telefon}</SectionText>
              <SectionText>{contactDatoData.email}</SectionText>
              <ContactSocial>
                <SocialBar contactClass="socialbar-contact" />
              </ContactSocial>
            </ContactText>
          </ContactInfo>
          <iframe
            className="google-map-contact"
            title="Glammy Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2560.1120382821628!2d19.903791415718118!3d50.08418917942647!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165b4a6d40c7ab%3A0xcc4cfecf82ac1a56!2sGlammy%20Beauty%26Mani!5e0!3m2!1spl!2spl!4v1650640020604!5m2!1spl!2spl"
            allowFullScreen={false}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </ContactContainer>
      </Layout>
    </>
  )
}

export default Contact

const ContactContainer = styled.div`
  padding: 10px;
  min-height: calc(100vh - 81px - 5vh);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  .google-map-contact {
    width: 50vw;
    height: 60vh;
    border-radius: 12px;
    box-shadow: 2px 2px 4px rgba(230, 120, 100, 0.4);
    @media screen and (max-width: 768px) {
      margin-top: 10px;
      width: 95vw;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const ContactInfo = styled.div`
  padding: 10px;
  min-height: 70vh;
  width: 50vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
  @media screen and (max-width: 768px) {
    width: 95vw;
  }
`

const ContactHours = styled.div`
  padding: 15px;
  width: 70%;
  min-height: 40vh;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  font-size: 1.2rem;
  font-family: "Gentium Book Basic";
  font-style: italic;
  text-shadow: 1px 1px 2px #aaa;

  .weekday {
    text-decoration: underline;
  }

  @media screen and (max-width: 768px) {
    width: 90%;
    justify-content: space-around;
  }
`

const ContactHourInfo = styled.div`
  padding: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const ContactText = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3vh;
  align-items: center;
  font-size: 1.5rem;
`

const ContactSocial = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
